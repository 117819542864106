import React from 'react';
import { Box, Column, Heading } from 'gestalt';
import 'gestalt/dist/gestalt.css';

const Header = () =>  {
    return (
      
    <Box display="flex" direction="row" paddingY={2} color={'WHITE'}>
    <img align='top' height='101' text="Continuar com Google" src="https://lucaslisboa.org/img/logo.png"/>
    <Column span={10}>
      <Box padding={3}>
        <Heading size="sm"></Heading>
      </Box>
    </Column>
    </Box>);
}

export default Header;