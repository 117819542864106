import React, { useState } from 'react';
import { Link, navigate } from '@reach/router';
import { auth, signInWithGoogle } from '../../firebase/firebase';
import {
  Box,
  Button,
  Toast,
  Container,
  Text,
  TextField,
  Heading,
} from 'gestalt';
import 'gestalt/dist/gestalt.css';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    auth.signInWithEmailAndPassword(email, password).catch(error => {
      setError("Por favor, verifique se o email e a senha informados estão corretos!");
      console.error("Error signing in with password and email", error);
    });
  };
  
 



  return (
      
    <div>

      <Box padding={3}>
    
        <Container>
        
          <Box padding={3}>
            {error !== null && <Toast text={error} />}
            <Heading size="md"></Heading>
          </Box>
          <Box padding={3} >
            <Heading size="md"></Heading>
          </Box>
          <Box padding={2}>
          <img align='top' height='35px' class="google-icon" text="Continuar com Google" onClick={signInWithGoogle}  src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"/>
            <Button onClick={signInWithGoogle} text="Continuar com Google" color="red" inline />

          </Box>
          <Box padding={2}>
            <Text>ou</Text>
          </Box>
          <Box padding={2}>
            <TextField
              id="email"
              onChange={event => setEmail(event.value)}
              placeholder="Por favor, informe o e-mail"
              label="E-mail"
              value={email}
              type="email"
            />
          </Box>
          <Box padding={2}>
            <TextField
              id="password"
              onChange={event => setPassword(event.value)}
              placeholder="Por favor, informe a senha de acesso"
              label="Senha"
              value={password}
              type="password"
            />
          </Box>
          <Box padding={2}>
            <Button
              onClick={event => {
                signInWithEmailAndPasswordHandler(event, email, password);
                navigate('/');
              }}
              text="Continuar"
              color="blue"
              inline
            />
          </Box>

          
          <Box padding={2} hidden>
            <Text><br></br><b>Não tem um cadastro?</b></Text>
          </Box>
          <Box padding={2} hidden>
            <Link to="signUp" className="text-blue-500 hover:text-blue-600">
              <b>Por favor, cadastre-se aqui!</b>
            </Link>
          </Box>
          <Box padding={2}>
          <Link
              to="passwordReset"
              className="text-blue-500 hover:text-blue-600"
              hidden>
              Esqueci a senha.
            </Link>
          </Box>
        </Container>
      </Box>
    </div>
  );
};
export default SignIn;
